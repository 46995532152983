<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-0 mt-2">
      {{ $t('pages.history.name') }}
    </h1>
    <h5 class="mt-0">
      {{ formatDate }}
    </h5>

    <div class="mt-3 mb-3">
      <template v-if="isLoad">
        <h4 class="mb-50">
          {{ stat.emoji }} {{ stat.name }}
        </h4>

        <div class="d-flex mb-2 pb-50">
          <h7>📦 &nbsp; <span class="text-primary pr-25">{{ numberFormat(stat.quantity) }}</span>
            {{ $t('units.package') }}
          </h7>
          <h7 class="ml-2">
            ⚖️ &nbsp; <span class="text-primary pr-25">{{ numberFormat(stat.total_weight) }}</span> {{ $t('units.kg') }}
          </h7>
        </div>

        <template v-if="!isTablet">
          <touch-button-group class="w-100 mb-2 d-flex">
            <touch-button
              class="flex-fill"
              :class="{'active' : mode === modeSimplified}"
              ingroup
              outline
              thinkiness
              size="sm"
              style="width: 33%"
              @action="mode = modeSimplified"
            >
              <span class="wrap">{{ $t('therms.simplified-view') }}</span>
            </touch-button>
            <touch-button
              class="flex-fill"
              :class="{'active' : mode === modeGraphical}"
              ingroup
              outline
              thinkiness
              size="sm"
              style="width: 33%"
              @action="mode = modeGraphical"
            >
              <span class="wrap">{{ $t('therms.graphical-view') }}</span>
            </touch-button>
          </touch-button-group>

          <div class="animate__animated animate__fadeInUp">
            <div
              v-if="mode === modeSimplified"
            >
              <ProductionStatCard
                v-if="Object.keys(stat.types).length>=1"
                class="mb-1"
                :title="$t('therms.per-crop-types').toString()"
                :items="Object.values(stat.types)"
                :max-quantity="stat.total_weight"
              />

              <ProductionStatCard
                v-if="stat.options.includes('packings') !== null && Object.keys(stat.packings).length>=1"
                class="mb-1"
                :title="$t('therms.per-crop-packings').toString()"
                :items="Object.values(stat.packings)"
                :max-quantity="stat.total_weight"
                variant="warning"
              />

              <ProductionStatCard
                v-if="stat.options.includes('grades') !== null && Object.keys(stat.grades).length>=1"
                class="mb-1"
                :title="$t('therms.per-crop-grades').toString()"
                :items="Object.values(stat.grades)"
                :max-quantity="stat.total_weight"
                variant="danger"
              />

              <ProductionStatCard
                v-if="Object.keys(stat.plots).length>=1"
                class="mb-1"
                :title="$t('therms.per-plots').toString()"
                :items="Object.values(stat.plots)"
                :max-quantity="stat.total_weight"
                variant="info"
              />

              <ProductionStatCard
                v-if="Object.keys(stat.accounts).length>=1"
                class="mb-1"
                :title="$t('therms.per-accounts').toString()"
                :items="Object.values(stat.accounts)"
                :max-quantity="stat.total_weight"
                variant="danger"
              />
            </div>
            <div
              v-if="mode === modeGraphical"
            >
              <ProductionBarChartCard
                v-if="Object.keys(stat.types).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1"
                :title="$t('therms.analyze-per-crop-types').toString()"
                :labels="typeChartData.labels"
                :values="typeChartData.values"
                :color="typeChartData.color"
              />
              <ProductionBarChartCard
                v-if="stat.options.includes('packings') !== null && Object.keys(stat.packings).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1"
                :title="$t('therms.analyze-per-crop-packings').toString()"
                :labels="packingChartData.labels"
                :values="packingChartData.values"
                :color="packingChartData.color"
              />
              <ProductionBarChartCard
                v-if="stat.options.includes('grades') !== null && Object.keys(stat.grades).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1"
                :title="$t('therms.analyze-per-crop-grades').toString()"
                :labels="gradesChartData.labels"
                :values="gradesChartData.values"
                :color="gradesChartData.color"
              />
              <ProductionBarChartCard
                v-if="Object.keys(stat.plots).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1"
                :title="$t('therms.analyze-per-plots').toString()"
                :labels="plotsChartData.labels"
                :values="plotsChartData.values"
                :color="plotsChartData.color"
              />
              <ProductionBarChartCard
                v-if="Object.keys(stat.accounts).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1"
                :title="$t('therms.analyze-per-accounts').toString()"
                :labels="accountsChartData.labels"
                :values="accountsChartData.values"
                :color="accountsChartData.color"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="mt-4" />
          <b-row>
            <b-col
              xl="12"
              class="mb-1"
            >
              <h6>{{ $t('therms.simplified-view') }}</h6>
            </b-col>
            <b-col
              v-if="Object.keys(stat.types).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionStatCard
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-crop-types').toString()"
                :items="Object.values(stat.types)"
                :max-quantity="stat.total_weight"
              />
            </b-col>
            <b-col
              v-if="stat.options.includes('packings') !== null && Object.keys(stat.packings).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionStatCard
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-crop-packings').toString()"
                :items="Object.values(stat.packings)"
                :max-quantity="stat.total_weight"
                variant="warning"
              />
            </b-col>
            <b-col
              v-if="stat.options.includes('grades') !== null && Object.keys(stat.grades).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionStatCard
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-crop-grades').toString()"
                :items="Object.values(stat.grades)"
                :max-quantity="stat.total_weight"
                variant="danger"
              />
            </b-col>
            <b-col
              v-if="Object.keys(stat.plots).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionStatCard
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-plots').toString()"
                :items="Object.values(stat.plots)"
                :max-quantity="stat.total_weight"
                variant="info"
              />
            </b-col>
            <b-col
              v-if="Object.keys(stat.accounts).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionStatCard
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-accounts').toString()"
                :items="Object.values(stat.accounts)"
                :max-quantity="stat.total_weight"
                variant="danger"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              xl="12"
              class="mb-1"
            >
              <h6>{{ $t('therms.graphical-view') }}</h6>
            </b-col>
            <b-col
              v-if="Object.keys(stat.types).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionBarChartCard
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-types').toString()"
                :labels="typeChartData.labels"
                :values="typeChartData.values"
                :color="typeChartData.color"
              />
            </b-col>
            <b-col
              v-if="stat.options.includes('packings') !== null && Object.keys(stat.packings).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionBarChartCard
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-packings').toString()"
                :labels="packingChartData.labels"
                :values="packingChartData.values"
                :color="packingChartData.color"
              />
            </b-col>
            <b-col
              v-if="stat.options.includes('grades') !== null && Object.keys(stat.grades).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionBarChartCard
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-grades').toString()"
                :labels="gradesChartData.labels"
                :values="gradesChartData.values"
                :color="gradesChartData.color"
              />
            </b-col>
            <b-col
              v-if="Object.keys(stat.plots).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionBarChartCard
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-plots').toString()"
                :labels="plotsChartData.labels"
                :values="plotsChartData.values"
                :color="plotsChartData.color"
              />
            </b-col>
            <b-col
              v-if="Object.keys(stat.accounts).length>=1"
              xs="12"
              md="6"
              lg="6"
              class="mb-2 pb-75"
            >
              <ProductionBarChartCard
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-accounts').toString()"
                :labels="accountsChartData.labels"
                :values="accountsChartData.values"
                :color="accountsChartData.color"
              />
            </b-col>
          </b-row>
        </template>

        <HistorySheetDetails
          v-if="sheetsCount>=1"
          class="animate__animated animate__fadeInUp mb-2 pb-50"
          :items="sheets"
        />
      </template>
      <template v-else>
        <div
          class="mt-4 mb-4 pt-3 pb-3 d-flex align-items-center justify-content-center flex-fill"
        >
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import chartColors from '@core/mixins/charts/colors'
import { BCol, BRow, BSpinner } from 'bootstrap-vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
import HistorySheetDetails from '@/views/pages/Histories/components/HistorySheetDetails.vue'
import ProductionBarChartCard from '@/views/components/ProductionBarChartCard.vue'
import ProductionStatCard from '@/views/components/ProductionStatCard.vue'
// eslint-disable-next-line import/no-cycle
import { NAME_HISTORY } from '@/router/routes/history'

export default {
  components: {
    HistorySheetDetails,
    NavButtonBar,
    NavButton,
    ProductionBarChartCard,
    ProductionStatCard,
    TouchButtonGroup,
    TouchButton,
    BSpinner,
    BRow,
    BCol,
  },
  data() {
    return {
      // ui
      error: false,
      loader: false,
      mode: 'simplified',

      // data
      date: null,
      sheets: null,
      stat: null,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {string}
     */
    modeSimplified() {
      return 'simplified'
    },
    /**
     * @returns {string}
     */
    modeGraphical() {
      return 'graphical'
    },
    /**
     * @returns {string}
     */
    formatDate() {
      return this
        .$moment(this.date)
        .format('DD/MM/YYYY')
    },
    /**
     * @returns {Number}
     */
    sheetsCount() {
      if (this.sheets === null) return 0

      return Object.values(this.sheets).length
    },
    /**
     * @returns {boolean}
     */
    isLoad() {
      return this.sheets !== null && this.stat !== null && this.date !== null
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    typeChartData() {
      return {
        labels: Object.values(this.stat.types)
          .map(e => e.name),
        values: Object.values(this.stat.types)
          .map(e => e.total_weight),
        color: chartColors.warningColorShade,
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    packingChartData() {
      return {
        labels: Object.values(this.stat.packings)
          .map(e => e.name),
        values: Object.values(this.stat.packings)
          .map(e => e.total_weight),
        color: chartColors.warningLightColor,
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    gradesChartData() {
      return {
        labels: Object.values(this.stat.grades)
          .map(e => e.name),
        values: Object.values(this.stat.grades)
          .map(e => e.total_weight),
        color: chartColors.lineChartDanger,
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    plotsChartData() {
      return {
        labels: Object.values(this.stat.plots)
          .map(e => e.name),
        values: Object.values(this.stat.plots)
          .map(e => e.total_weight),
        color: chartColors.infoColorShade,
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    accountsChartData() {
      return {
        labels: Object.values(this.stat.accounts)
          .map(e => e.name),
        values: Object.values(this.stat.accounts)
          .map(e => e.total_weight),
        color: chartColors.lineChartDanger,
      }
    },
  },
  async mounted() {
    try {
      this.date = this.$moment(this.$router.currentRoute.params.date)
        .locale('en')
        .format('YYYY-MM-DD')

      this.error = false
      this.loader = true
      this.sheets = null
      this.stat = null

      if (!await this.$store.dispatch('shipmentsHistory/has', this.date)) {
        this.loader = false
        await this.$router.replace({ name: NAME_HISTORY })
        return
      }

      await this.$store.dispatch('shipmentsHistory/load', {
        date: this.date,
      })

      this.sheets = await this.$store.dispatch('shipmentsHistory/getSheets', {
        date: this.date,
        crop: this.$router.currentRoute?.params?.crop_id ?? null,
      })

      // eslint-disable-next-line prefer-destructuring
      this.stat = Object.values(await this.$store.dispatch('shipmentsHistory/getStats', {
        date: this.date,
        crop: this.$router.currentRoute?.params?.crop_id ?? null,
      }))[0]

      this.loader = false
    } catch (err) {
      this.loader = false
      this.error = true

      throw err
    }
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
