<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

div {
  border-radius: 0.828rem;
  border: 1px solid #31B9B1 !important;
  overflow: hidden;

  &::v-deep {
    *:last-child {
      border-right: none !important;
    }
  }
}
</style>
