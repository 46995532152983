<template>
  <b-card
    :title="title"
  >
    <chartjs-component-bar-chart
      :height="270"
      :data="chart"
      :options="options"
    />
  </b-card>
</template>

<script>
import chartColors from '@core/mixins/charts/colors'
import { $themeColors } from '@appConfig'
import { BCard } from 'bootstrap-vue'
import ChartjsComponentBarChart from '@core/components/charts/ChartjsComponentBarChart.vue'

export default {
  components: {
    BCard,
    ChartjsComponentBarChart,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    labels: {
      required: true,
      type: Array,
    },
    values: {
      required: true,
      type: Array,
    },
    unit: {
      required: true,
      type: String,
    },
    color: {
      default: chartColors.greyColor,
      type: String,
    },
    borderColor: {
      default: 'transparent',
      type: String,
    },
    customOptions: {
      default() {
        return {}
      },
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    /**
     * @returns {{datasets: [{backgroundColor: string, borderColor: string, data: *[]}], labels: *[]}}
     */
    chart() {
      return {
        labels: this.labels,
        datasets: [
          {
            data: this.values,
            backgroundColor: this.color,
            borderColor: this.borderColor,
          },
        ],
      }
    },
    /**
     * @returns {{legend: {display: boolean}, elements: {rectangle: {borderSkipped: string, borderWidth: number}}, responsive: boolean, scales: {yAxes: [{ticks: {min: number, max: number, stepSize: number, fontColor: string}, display: boolean, gridLines: {color: string, zeroLineColor: string}}], xAxes: [{ticks: {fontColor: string}, display: boolean, scaleLabel: {display: boolean}, gridLines: {color: string, display: boolean, zeroLineColor: string}}]}, maintainAspectRatio: boolean, responsiveAnimationDuration: number, tooltips: {shadowOffsetX: number, titleFontColor: (string|*), shadowOffsetY: number, backgroundColor, shadowBlur: number, bodyFontColor: (string|*), shadowColor: string}}}
     */
    options() {
      return {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
          callbacks: {
            label: tooltipItem => `${window.numberFormat(tooltipItem.yLabel)} ${this.unit}`,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                callback: val => `${window.numberFormat(val)} ${this.unit}`,
                stepSize: 1000,
                min: 0,
                max: Math.ceil(Math.max(...this.values) + 500),
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
        ...this.customOptions,
      }
    },
  },
}
</script>
